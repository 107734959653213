import EventEmitter from './event-emitter'

export default class Modal extends EventEmitter
{
    constructor() {
        super() 
        this.isReady = false
        this.isOpen = false
        this.clickEvent = false
        this.content = null
        
        jQuery(document).ready( $ => {
            this.isReady = true
            this.magnificPopup = $.magnificPopup
            window.Modal.trigger('ready')
        })

        this.on('close', ()=> {
            this.content = null
            this.isOpen = false
            this.isClick = false
        })
        

        window.Modal = this
    }

    open() 
    {
        if(this.isOpen) return 
        if(this.content == null) return

        this.isOpen = true
        // this.container = elem
        // append html into container
        // this.container.append(html)

        // console.log(this.content)
        // open popup
        this.magnificPopup.open({
            removalDelay: 500,
            mainClass: 'mfp-anim',
            items: {
                src: this.content,
                type: 'inline'
            },
            callbacks: {
                beforeOpen: ()=> {
                    window.Modal.trigger('beforeOpen', this)
                },
                open: function() {
                    window.Modal.trigger('open', this)
                },
                beforeClose: ()=> {
                    window.Modal.trigger('beforeClose', this)
                },
                close: ()=> {
                    window.Modal.trigger('close')
                },
                resize: function() {
                    window.Modal.trigger('resize')
                },
            }
        })
    }
    close() 
    {
        this.magnificPopup.close()
    }
}